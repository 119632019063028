import React from "react";
import {useHistory} from "react-router-dom";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import gs from "../../Theme/common.module.scss";

interface Props {
    hash: string,

    onClickView(hash: string): void
}

function ViewOffer({hash, onClickView}: Props) {
    const history = useHistory();

    if (hash === 'undefined') {
        history.push('/');
    }

    return <ScrollTop>
        <div className={c(bs['text-center'], bs['my-5'])}>
            <h2 className={c(bs['text-center'], bs['my-5'])}>Hier gehts zu Ihren persönlichen NeoFlow-Angeboten</h2>
            <button onClick={() => onClickView(hash)} className={c(gs.bigRedButton, bs['my-5'])}>
                Lüftungslösungen anzeigen
            </button>
            <p className={c(bs['my-5'])}>
                <b>Vielen Dank für Ihr Interesse an NeoFlow.</b><br/>
                Sie erhalten in Kürze ein E-Mail mit Ihren passenden Lüftungslösungen inklusive Fixpreis-Angeboten.
            </p>
        </div>
    </ScrollTop>
}

export default ViewOffer;
