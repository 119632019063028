import React, {useEffect, useState} from "react";
import {Submission} from "../Main/Main";
import c from "classnames";
import bs from "../../Theme/bootstrap.module.css";
import s from "./Submit.module.scss";
import gs from "../../Theme/common.module.scss";
import ScrollTop from "../../Components/ScrollTop/ScrollTop";
import {checkMail, checkName, checkPhone} from "../../Helper/RegexHelper";

interface Props {
    onSubmit?(partialSubmission: Submission): void
}

function Submit(props: Props) {
    const [name, setName] = useState('');
    const [nameValid, setNameValid] = useState<boolean>(true);
    useEffect(() => {
        setNameValid(checkName(name));
    }, [name])

    const [mail, setMail] = useState('');
    const [mailValid, setMailVaild] = useState<boolean>(true);
    useEffect(() => {
        setMailVaild(checkMail(mail));
    }, [mail])

    const [tel, setTel] = useState('');
    const [telValid, setTelValid] = useState<boolean>(true);
    useEffect(() => {
        setTelValid(checkPhone(tel));
    }, [tel])

    const [marketing, setMarketing] = useState<boolean>(false);
    const [dsgvo, setDSGVO] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false)
    useEffect(() => {
        setError(false);
    }, [name, nameValid, mail, mailValid, tel, telValid, dsgvo])

    const handleSubmit = () => {
        if (name.length && nameValid && mail.length && mailValid && tel.length && telValid && dsgvo) {
            if (typeof props.onSubmit === "function") {
                props.onSubmit({
                    Name: name,
                    Email: mail,
                    Phone: tel
                })
            }
            setError(false);
        } else {
            setError(true);
        }
    }

    return <ScrollTop>
        <p className={c(bs['mb-5'])}>Erhalten Sie jetzt Ihre passenden Lüftungslösungen inklusive Fixpreis-Angebot per
            E-Mail, sowie Zugang zu Ihrer persönlichen Online-Angebotsseite.</p>
        <h2>Ihr Angebot wurde berechnet.</h2>
        <h3 className={c(bs['mb-5'])}>Angebot jetzt anfordern</h3>
        <div className={c(bs['mb-5'], bs['row'])}>
            <label htmlFor="name" className={c(bs['col-12'], bs['col-form-label'])}>
                Vor- und Nachname*
            </label>
            <div className={c(bs['col-12'])}>
                <input type="text"
                       className={c(bs['form-control'], gs['customFormControl'], name.length ? (nameValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                       id="name"
                       placeholder="Michaela Mustermann"
                       autoComplete="name"
                       value={name}
                       onChange={(e) => setName(e.target.value)}
                />
            </div>
        </div>
        <div className={c(bs['mb-5'], bs['row'])}>
            <label htmlFor="mail" className={c(bs['col-12'], bs['col-form-label'])}>
                E-Mail*
            </label>
            <div className={c(bs['col-12'])}>
                <input type="text"
                       className={c(bs['form-control'], gs['customFormControl'], mail.length ? (mailValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                       id="mail" placeholder="michaela@mustermann.com"
                       autoComplete="email"
                       value={mail}
                       onChange={(e) => setMail(e.target.value)}
                />
            </div>
        </div>
        <div className={c(bs['mb-5'], bs['row'])}>
            <label htmlFor="tel" className={c(bs['col-12'], bs['col-form-label'])}>
                Telefon*
            </label>
            <div className={c(bs['col-12'])}>
                <input type="text"
                       className={c(bs['form-control'], gs['customFormControl'], tel.length ? (telValid ? bs['is-valid'] : bs['is-invalid']) : null)}
                       id="tel" placeholder="+43 7232 123456"
                       autoComplete="tel"
                       value={tel}
                       onChange={(e) => setTel(e.target.value.match(/[+ 0-9]*/)?.join('') ?? '')}
                />
            </div>
        </div>
        <div className={c(bs['mb-3'], bs['row'])}>
            <div className={c(bs['col-12'], bs['mt-3'])}>
                <label className={c(s.customCbx)} htmlFor="dsgvoAgreement">
                    <input type="checkbox" id="dsgvoAgreement"
                           className={c(s.customCbx, bs['d-none'])}
                           checked={dsgvo}
                           onChange={() => setDSGVO(!dsgvo)}/>
                    <span className={c(s.customCbx)}></span>
                    <span className={c(s.customCbxText)}>
      Ich akzeptiere die Datenschutzbestimmungen und die allgemeinen Geschäftsbedingungen von Hoval. *
            </span>
                </label>
            </div>

            <p className={bs['mt-3']}>
                <a target="_blank"
                   href="https://www.hoval.at/de_AT/Datenschutzerkl%C3%A4rung/datenschutzerklaerung">
                    Datenschutzbestimmungen
                </a> - <a target="_blank"
                   href="https://www.hoval.at/de_AT/Allgemeine-Gesch%C3%A4ftsbedingungen/agb">
                    AGB
                </a>
            </p>

            <div className={c(bs['col-12'], bs['mt-3'])}>
                <label className={c(s.customCbx)} htmlFor="marketingAgreement">
                    <input type="checkbox" id="marketingAgreement"
                           className={c(s.customCbx, bs['d-none'])}
                           checked={marketing}
                           onChange={() => setMarketing(!marketing)}/>
                    <span className={c(s.customCbx)}></span>
                    <span className={c(s.customCbxText)}>
            Ich bin damit einverstanden, von Hoval Gesellschaft m.b.H telefonisch oder per E-Mail Informationen über Produkte und Dienstleistungen zu erhalten.<br/>
            Meine Zustimmung kann ich jederzeit mit einer kurzen formlosen Mitteilung per E-Mail an info.at@hoval.com widerrufen sowie per Klick auf die "Abmelden"-Funktion im Newsletter.
            </span>
                </label>
            </div>
        </div>
        <div>
            <p className={c(bs['mt-5'])}>
                Hinweis: Hoval möchte Sie vor einer etwaigen Anmeldung durch Dritte schützen. Sie erhalten daher in Kürze ein E-Mail von uns - klicken Sie bitte auf den darin enthalten Bestätigungslink.
                Erst danach erfolgt die Aufnahme in den Mailingverteiler. Wenn Sie kein E-Mail von Hoval erhalten, überprüfen sie bitte Ihren Spamordner.
            </p>
        </div>
        <div className={c(bs['my-5'], bs['text-start'])}>
            <p className={c(bs['text-danger'], error ? null : bs['d-none'])}>
                Bitte füllen Sie alle erforderlichen Felder aus!
            </p>
            <button className={gs.bigRedButton} onClick={handleSubmit}>Absenden</button>
        </div>
        <div className={c(s.smallInfoText)}>
            Beim Absenden des Formulars gehen Sie <b>keine Verpflichtungen</b> ein, und es entstehen <b>keine Kosten</b>. <br/> Sie erhalten
            per E-Mail unser Fixpreis-Angebot für eine fabelhafte Wohnraumlüftung sowie unsere Kontaktinformationen. Wir
            sind in allen Fragen zu Ihrem perfekten Raumklima gerne für Sie da!
        </div>
    </ScrollTop>
}

export default Submit;
